import './App.css';
import ParticleRing from './ParticleRing';

function App() {
  return (
    <ParticleRing />
  );
}

export default App;
